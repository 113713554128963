import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import GridItem from '../components/GridItem';

import LogoImage from '../images/logo-flat.png';

const Header = styled.header`
  width: 100%;
  height: 500px;
  position: relative;
  padding: 1.75rem;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    height: 275px;
  }
`;

const Logo = styled.h2`
  text-align: center;
  display: inline-block:
  margin: 2rem auto 0 auto;
  padding: 0 1.75rem;
`;

const Hero = styled(Flex)`
  height: 100%;
  text-align: center;
  h1 {
    letter-spacing: 0.2rem;
    line-height: 4.5rem;
  }
  h3 {
    font-family: ${props => props.theme.fontFamily.body};
    margin-top: 2rem;
    font-size: 1.85rem;
    font-weight: 400;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    h1 {
      line-height: 3.5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    h1 {
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
  }
`;

const Wrapper = styled(Flex)`
  max-width: ${props => props.theme.maxWidth};
`;

const Contact = styled(Wrapper)`
  margin: 0 auto;
  h1,
  h2,
  h3 {
    color: ${props => props.theme.colors.text};
  }
  h3 {
    font-family: ${props => props.theme.fontFamily.body};
    margin: 0;
    font-size: 1.85rem;
    font-weight: 400;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    font-size: 1.5rem;
  }
`;

const IndexPage = ({ props }) => (
  <Layout>
    <Header>
      <Logo>
        <img src={LogoImage} alt="EMEICH" />
      </Logo>
      <Hero justifyContent="center" alignItems="center" flexDirection="column">
        <h1>no hago cosas productivas, <br/> pero investigo todo el tiempo</h1>
      </Hero>
    </Header>
    <Contact px={4} py={6} justifyContent="center" alignItems="center" flexDirection="column">
      <p>fala: <a href="https://twitter.com/mhhansen">@mhhansen</a></p>
    </Contact>
    <Footer />
  </Layout>
);

export default IndexPage;
