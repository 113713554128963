module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'EMEICH', // Navigation and Site Title
  siteTitleAlt: 'EMEICH', // Alternative Site title for SEO
  siteUrl: 'https://emeich.com', // Domain of your site. No trailing slash!
  siteLanguage: 'es', // Language Tag on <html> element
  siteLogo: '/logos/logo-flat-512.png', // Used for SEO and manifest
  siteDescription: 'Articulos y código sobre desarrollo web.',
  author: 'EMEICH', // Author for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@mhhansen', // Twitter Username
  ogSiteName: 'emeich', // Facebook Site Name
  ogLanguage: 'es_AR', // Facebook Language

  // Manifest and Progress color
  themeColor: '#10b712',
  backgroundColor: '#333333',
};
